import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';
import SplitPane from 'react-split-pane';
import DocumentTitle from 'react-document-title';
import { Button, ControlGroup,InputGroup, EditableText, Tooltip, Position, TextArea, Hotkey, Hotkeys  } from "@blueprintjs/core";
import { HotkeysTarget } from "@blueprintjs/core/lib/esnext/components/hotkeys/hotkeysTarget";
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import ChapterItem from '../component/ChapterItem'; 
import BookInfoBox from '../component/BookInfoBox'; 
import AvatarUploadButton from '../component/AvatarUploadButton'; 
import AvatarCircle from '../component/AvatarCircle'; 
import AvatarSquare from '../component/AvatarSquare'; 
import TalkListEditable from '../component/TalkListEditable'; 
import TalkList from '../component/TalkList';
import TalkListPrint from '../component/TalkListPrint'; 

import SendForm from '../component/SendForm';
import OpenFileButton from '../component/OpenFileButton'; 
import TrafficLight from '../component/TrafficLight'; 
import DragDiv from '../component/DragDiv'; 
import LocalFileButton from '../component/LocalFileButton'; 
import AIImportDialog from '../component/AIImportDialog';
import ClearDialog from '../component/ClearDialog';
import UploadDialog from '../component/UploadDialog';
import DebugDialog from '../component/DebugDialog';
import ExportDialog from '../component/ExportDialog';




@withRouter
@translate()
@inject("store")
@observer
export default class Editor extends Component
{
    constructor(props) 
    {
        super(props);
        this.list_ref = React.createRef();
        this.end_ref = React.createRef();
        this.state = {
            showAIImport: false,
            showClearDialog: false,
            showUploadDialog: false,
            showDebugDialog: false,
            showExportDialog: false
        };
    }

    componentDidMount()
    {
       this.props.store.load(); 
       // console.log( "loaded" + this.props.store.show_preview );
    }
   

    createTalk()
    {
        const store = this.props.store;
        
        store.tosend_text = store.tosend_text.trim();
        if( store.tosend_text.length < 1 ) return false;

        // 检查是否是多行模式
        const isMultiline = store.tosend_text.includes("\n---\n");

        store.tosend_text.split("\n---\n").forEach( item =>
        {
            if( item.trim().length > 0 )
            {
                const trimmedItem = item.trim();
                let roleMatch = trimmedItem.match(/^(.+?)[:：]/);
                let text = trimmedItem;
                let roleId = store.tosend_role_id; // 默认使用当前选中的角色

                // 只在多行模式下检测角色名
                if(roleMatch && isMultiline) {
                    const roleName = roleMatch[1].trim();
                    text = trimmedItem.substring(roleMatch[0].length).trim();
                    
                    // 查找现有角色
                    let foundRole = store.bookdata.roles.find(r => r.name === roleName);
                    
                    if(!foundRole) {
                        // 创建新角色
                        const newRoleId = parseInt(store.maxid(store.bookdata.roles)) + 1;
                        foundRole = {
                            id: newRoleId,
                            name: roleName,
                            avatar: "",
                            note: roleName,
                            ismain: false,
                            isstar: false
                        };
                        store.bookdata.roles.push(foundRole);
                    }
                    
                    roleId = foundRole.id;
                }

                // 临时保存要使用的角色ID
                const currentRoleId = roleId;
                store.tosend_text = text;

                if( store.focused_talk_id > 0  )
                {
                    // 在创建台词前设置正确的角色ID
                    const originalRoleId = store.tosend_role_id;
                    store.tosend_role_id = currentRoleId;
                    store.appendTalk( store.focused_talk_id );
                    store.tosend_role_id = originalRoleId;
                    store.tosend_text = "";
                }
                else
                {
                    // 在创建台词前设置正确的角色ID
                    const originalRoleId = store.tosend_role_id;
                    store.tosend_role_id = currentRoleId;
                    store.createTalk();
                    store.tosend_role_id = originalRoleId;
                    store.tosend_text = "";
                    this.toBottom();
                }
            }
        })       
    }

    toBottom = () =>
    {
        this.end_ref.current.scrollIntoView( true );
    }

    roleModify( id )
    {
        const store = this.props.store;
        if( id < 1 )
        {
            store.toast("旁白角色不可修改");
            return false;
        }
        store.modifyRole( id );
        // console.log( store.editing_role );
    }

    roleRemove( id )
    {
        const store = this.props.store;
        if( id < 1 )
        {
            store.toast("旁白角色不可删除");
            return false;
        }

        if( !window.confirm("角色删除后，所有角色的台词都将同步删除，请务必确认后继续") ) return  false;

        store.removeRole( id );
    }

    clean()
    {
        this.setState({ showClearDialog: true });
    }

    roleStar( id )
    {
        const store = this.props.store;
        if( id < 0 )
        {
            store.toast("错误的角色ID");
            return false;
        }
        store.starRole( id );
        // console.log( store.editing_role );
    }

    roleMain( id )
    {
        const store = this.props.store;
        if( id < 1 )
        {
            store.toast("旁白角色不可设为主角");
            return false;
        }
        store.mainRole( id );
        // console.log( store.editing_role );
    }

    createChapter()
    {
        const store = this.props.store;
        store.createChapter();
        window.location.reload();
    }

    changeRole( id = -1 )
    {
        // console.log( "change" , id );
        const store = this.props.store;
        
        if( id < 0 )
            store.set_tosend_role( store.nextRole( store.tosend_role_id ) );
        else
            store.set_tosend_role( id );   
    }

    saveToFile()
    {
        const store = this.props.store;
        store.saveToFile();
    }

    

    render()
    {
        const store = this.props.store;

        const SortableItem = SortableElement(({value}) =><ChapterItem data={value} key={value.id} />);

        
        const SortableList = SortableContainer(({items}) => {
            return (
            <ul ref={this.list_ref}>
                {items.map((item , index ) => (
                <SortableItem key={item.id}  index={index} value={item} />
                ))}
            </ul>
            );
        });
        const side_plane = <div className="side">
            <div className="chapter-box">
                <div className="search">
                <ControlGroup fill={true} vertical={false}>
                    <InputGroup className="gray" leftIcon="filter" placeholder="章节名称关键字..." onChange={(e)=>store.chapter_filter=e.target.value} value={store.chapter_filter}/>
                    <Button icon="small-plus" alt="" onClick={()=>this.createChapter()} />
                </ControlGroup>
                </div>
                <div className="list">
                   

                    { store.filteredChapters && 
                    <SortableList 
                        items={store.filteredChapters} 
                        useDragHandle 
                        onSortEnd={( {oldIndex, newIndex} ) => store.reOrder( oldIndex , newIndex )}
                        helperContainer={()=>this.list_ref.current}
                    /> } 
                    
                </div>
            </div>
            <div className="chapter-intro">
            <div className="introbox">
            <EditableText 
            onConfirm={(text)=>store.saveChapterNote( text )}
            onChange={(text)=>store.saveChapterNote( text , false )}
            value={store.currentChapter && store.currentChapter.notes}
            multiline={true} 
            minLines={3}
            placeholder="本章笔记[仅作者可见]"
            />
            </div>
            </div>
        </div>;

        const story_plane = <div className="story">
        <div className="editbox">
            <div className="talklist"><TalkListEditable refs={this.end_ref}/></div>
            <div className="talkform">
                <div className="left">{store.tosend_role && <div className="rolecard">
                    <AvatarSquare data={store.tosend_role} className="shadow s64" onClick={()=>this.changeRole()}/>
                    <div className="top10">{store.tosend_role.note||store.tosend_role.name}</div>
                </div> }</div>
                <div className="right">
                    
                    
                    <div className="textarea">
                    <SendForm 
                        changeRole={(id)=>this.changeRole(id)}  
                        createTalk={()=>this.createTalk()}  
                    />
                    </div>
                    <div className="actions">
                        <Button text={ store.focused_talk_id > 0 ? "追加到选中项后":"添加" } large={true} onClick={()=>this.createTalk()}/>
                    </div>
                    
                </div>
            </div>
        </div>
        { store.show_print ? <div className="printbox">
        <TalkListPrint roles={store.bookdata.roles} talks={store.currentTalks} />
        </div> : store.show_preview && <div className="previewbox">
            {store.currentTalks && <TalkList talks={store.previewTalks} roles={store.bookdata.roles} current_talk_id={store.preview_talk_id} />  }
        </div> }
        
        </div>;

        const main = <div className="h2editor-page">
        <SplitPane split="vertical" minSize={40} defaultSize={40}>
            <div className="toolbar">
                <div className="logo"><img src="./image/h2editor.logo.png" alt="logo" className="logo-image" onClick={()=>store.load()}/></div>
                <Tooltip content="新增角色" position={Position.RIGHT}><AvatarUploadButton data={store.send_role} editing_role={store.editing_role} onFinished={()=>store.editing_role=false} /></Tooltip>

                <Tooltip content="保存文档" position={Position.RIGHT}><Button icon="floppy-disk" minimal={true} onClick={()=>this.saveToFile()} /></Tooltip>

                <Tooltip content="打开文档" position={Position.RIGHT}>
                { window.require ? <LocalFileButton/> : <OpenFileButton/> }   
                </Tooltip> 

                <Tooltip content="清空当前文档内容" position={Position.RIGHT}><Button icon="clean" minimal={true} onClick={()=>this.clean()} /></Tooltip> 

                <Tooltip content="开关预览面板" position={Position.RIGHT}><Button icon="panel-stats" minimal={true} onClick={()=>store.togglePreview()} /></Tooltip> 
                
                <Tooltip content="生成长图文件" position={Position.RIGHT}><Button icon="media" minimal={true} onClick={()=>store.exportImages()} /></Tooltip> 

                <Tooltip content="导出章节.h2book文件" position={Position.RIGHT}><Button icon="manual" minimal={true} onClick={()=>{
                    this.setState({showExportDialog: true});
                }} /></Tooltip>    

                <Tooltip content="AI生成" position={Position.RIGHT}><Button icon="rocket-slant" minimal={true} onClick={()=>this.setState({showAIImport: true})} /></Tooltip>

                <Tooltip content="发布到服务器" position={Position.RIGHT}><Button icon="cloud-upload" minimal={true} onClick={()=>this.setState({showUploadDialog: true})} /></Tooltip>

                <Tooltip content="调试对话数据" position={Position.RIGHT}>
                    <Button icon="code" minimal={true} onClick={()=>this.setState({showDebugDialog: true})} />
                </Tooltip>
            </div>

            <SplitPane split="vertical" minSize={160} defaultSize={ parseInt(localStorage.getItem('splitPos-1'), 10) || 200 }
                   onChange={ size => localStorage.setItem('splitPos-1', size) }
            >
                {side_plane}
                
                <SplitPane split="vertical" minSize={400} defaultSize={ parseInt(localStorage.getItem('splitPos-2'), 10) || 800 }
                onChange={ size => localStorage.setItem('splitPos-2', size) } >
                
                {story_plane}
                    
                <div className="resource">
                    <div className="left-section">
                        <div className="bookbox"><BookInfoBox/></div>
                        <div className="avatarlist">
                        { store.bookdata.roles && store.bookdata.roles.map( item => 
                                <AvatarCircle 
                                onUpdate={()=>this.roleModify( item.id )}  key={item.id}  onRemove={()=>this.roleRemove( item.id )} 
                                onStar={()=>this.roleStar( item.id )}
                                onMain={()=>this.roleMain( item.id )}
                                data={item} onClick={()=>store.set_tosend_role(item.id)} /> ) }    
                        </div>
                    </div>
                </div>
                    
                </SplitPane>
            </SplitPane>
        </SplitPane>
        <AIImportDialog isOpen={this.state.showAIImport} onClose={()=>this.setState({showAIImport: false})} />
        <ClearDialog isOpen={this.state.showClearDialog} onClose={()=>this.setState({showClearDialog: false})} />
        <UploadDialog isOpen={this.state.showUploadDialog} onClose={()=>this.setState({showUploadDialog: false})} />
        <DebugDialog isOpen={this.state.showDebugDialog} onClose={()=>this.setState({showDebugDialog: false})} store={store} />
        <ExportDialog isOpen={this.state.showExportDialog} onClose={()=>this.setState({showExportDialog: false})} />
        </div>;
        return <DocumentTitle title={this.props.store.appname}><>{main}
        <TrafficLight/>
        <DragDiv/>
        </></DocumentTitle>;
    }
}