import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Dialog, Button, Classes, FormGroup, InputGroup, Checkbox, Callout } from "@blueprintjs/core";
import { withRouter } from 'react-router-dom';

@withRouter
@inject("store")
@observer
export default class ExportDialog extends Component {
  state = {
    selectedChapters: new Set([]),
    bookTitle: '',
    author: '',
    link: '',
    description: ''
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      const { store } = this.props;
      const currentChapter = store.currentChapter;
      const bookinfo = store.bookdata.bookinfo || {};
      
      this.setState({
        selectedChapters: new Set([store.currentChapterNumber]),
        bookTitle: bookinfo.name || currentChapter?.title || '',
        author: currentChapter?.author || bookinfo.author || '',
        link: currentChapter?.link || bookinfo.link || '',
        description: currentChapter?.description || bookinfo.detail || ''
      });
    }
  }

  handleSelectAll = () => {
    const chapters = this.props.store.bookdata.chapters || [];
    const allChapterIds = new Set(chapters.map(chapter => chapter.id));
    this.setState({ selectedChapters: allChapterIds });
  };

  handleDeselectAll = () => {
    this.setState({ selectedChapters: new Set() });
  };

  handleToggleChapter = (chapterId) => {
    const selected = new Set(this.state.selectedChapters);
    selected.has(chapterId) ? selected.delete(chapterId) : selected.add(chapterId);
    this.setState({ selectedChapters: selected });
  };

  handleSubmit = () => {
    this.props.store.exportMultiChapters({
      chapters: Array.from(this.state.selectedChapters),
      bookInfo: {
        title: this.state.bookTitle,
        author: this.state.author,
        link: this.state.link,
        description: this.state.description
      }
    });
    this.props.onClose();
  };
  
  render() {
    
    console.log(this.state);
    
    const { isOpen, onClose } = this.props;
    const chapters = this.props.store.bookdata.chapters || [];

    return (
      <Dialog
        title="导出多章节"
        isOpen={isOpen}
        onClose={onClose}
        className="export-dialog"
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup label="书籍标题">
            <InputGroup
              value={this.state.bookTitle}
              onChange={(e) => this.setState({ bookTitle: e.target.value })}
            />
          </FormGroup>
          <FormGroup label="作者">
            <InputGroup
              value={this.state.author}
              onChange={(e) => this.setState({ author: e.target.value })}
            />
          </FormGroup>
          <FormGroup label="链接">
            <InputGroup
              value={this.state.link}
              onChange={(e) => this.setState({ link: e.target.value })}
            />
          </FormGroup>
          <FormGroup label="简介">
            <InputGroup
              value={this.state.description}
              onChange={(e) => this.setState({ description: e.target.value })}
            />
          </FormGroup>
          <div className="chapter-selection">
            <h5>选择章节:</h5>
            <Callout>
              <Button small onClick={this.handleSelectAll}>全选</Button>
              <Button small onClick={this.handleDeselectAll} style={{ marginLeft: '8px' }}>全不选</Button>
            </Callout>
            <div className="top10">
            {chapters.map(chapter => (
              <Checkbox
                key={chapter.id}
                checked={this.state.selectedChapters.has(chapter.id)}
                onChange={() => this.handleToggleChapter(chapter.id)}
                label={chapter.title}
              />
            ))}

            </div>
            
          </div>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={onClose}>取消</Button>
            <Button intent="primary" onClick={this.handleSubmit}>导出</Button>
          </div>
        </div>
      </Dialog>
    );
  }
}